export const TRANSLATIONS_ES = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "Mis apuestas",
    my_coupons: "Mis cupones",
    rounds: "Rondas",    
    bet_with_syndicates: "Sindicatos",
    
    syndicates: "Sindicatos",
    turnover: "Grupo actual",
    
    single_rows: "Filas individuales",
    pro_pick: "Elección profesional",
    often: "Gana a menudo",
    a_lot: "Gana mucho",
    system: "Sistema",
    row_distribution: "Distribución de filas",
    overview: "Descripción general",
    full_cover: "Cobertura total",
    half_cover: "Media cubertura",
    row_price: "Precio de fila",
    pay: "Realizar apuesta",
    
    unsettled_coupons: "Cupones sin liquidar",
    settled_coupons: "Cupones liquidados",
    stake: "Apostar",
    coupon_share_status_cancelled: "Cancelado",
    your_winnings_right_now: "Tus ganancias ahora mismo",
    correct_rows_now: "Selecciones correctas ahora",
    starts: "Empieza",
    correct_rows: "Filas correctas",
    prize: "Premio",
    your_rows: "Tus filas",
    profit: "Ganancia",
    st: "",
    total: "Resultado",
    rows: "filas",
    row: "fila",
    price_per_row: "Precio por fila",
    total_cost: "Coste total",
    paid: "Pagado",
    settled: "Establecido",
    channel_internet: "Canal: Internet",
    print: "Imprimir",
    share: "Compartir",    
    clear_bets: 'Borrar apuestas',

    
    reduce: "Reducir",
    no_reduced_systems_available: "No hay sistemas reducidos disponibles",
    round_history: "Historial de rondas",
    
    no_profit: "Sin ganancia",
    no_coupons: "Sin cupones",
    started: "Empezó a las",
    round: "Ronda",
    winners: "Ganadores",
    your_bet_is_paid: "¡Tu apuesta ha sido realizada!",
    do_you_want_to_place_bet: "¿Quieres hacer una apuesta de",
    
    highest_win: "Mayor ganancia",
    insufficient_funds: "Fondos insuficientes",
    cancel: "Cancelar",
    unable_to_complete_purchase: 'No se puede completar la compra',
    error_code: 'Código de error',

    event_goal: 'Gol',
    event_penalty: 'Penalti',
    event_missed_penalty: 'Penalti fallado',
    event_own_goal: 'Gol en propia puerta',
    event_var: 'VAR',
    event_yellowcard: 'Tarjeta amarilla',
    event_yellowred: 'Segunda tarjeta amarilla',
    event_substitution: 'Sustitución',
    event_redcard: 'Tarjeta roja',
    event_pen_shootout_goal: 'Gol de penalti',
    event_pen_shootout_miss: 'Penalti fallado',
    event_full_time: 'Tiempo completo',
    event_half_time: 'Medio tiempo',
    event_kickoff: 'Saque inicial',
    event_finished_after_extra: 'Tiempo extra terminado',

    out: 'Afuera',

    
    table: "Mesa",
    game_info: "Información del juego",
    statistics: "Estadísticas",
        
    team_name: "Equipo",
    table_abbreviation_num_games: "G",
    table_abbreviation_wins: "W",
    table_abbreviation_draws: "D",
    table_abbreviation_losses: "L",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'Cerrado',
    full_time: "Tiempo completo",
    round_status_finished : "Finalizado",
    half_time: 'Medio tiempo',
    
    current_rounds: "Rondas actuales",
    settled_rounds: "Rondas resueltas",
    no_rounds_available: "No hay rondas disponibles",

    row_statistics: 'Estadísticas de fila',
    
    showing: 'Demostración',    
    settings: 'Ajustes',
    match: 'Partido',
    result: 'Resultado',    
    bet_type: "Tipo de apuesta",    
    mathematical_system: "Sistema matematico",
    reduced_system: "Sistema reducido",
    no_system: "Sin sistema",
    select: "Seleccionar",
    is_draw: "Será dibujado con",
    is_drawn: "Dibujado",
    draw_disclaimer: 'Si está sujeto a sorteo:',
    num_correct_win: 'Número correcto/ganar',
    more_info: 'Más información',
    in_running: 'En corriendo',
  
    
    go_back: 'Regresa',

    to_syndicate: "Para sindicar",
    shares: "Comparte",
    share_price: "Precio de la acción",
    sold_shares: "Acciones vendidas",
    agent_admin: "Administrador del agente",
    agents: "Agentes",
    to_agent: "Al agente",
    create_new_syndicate: "Crear nuevo sindicato",
    name: "Nombre",
    target_number_of_shares: "Número objetivo de acciones",
    max_number_of_shares: "Número máximo de acciones",
    submit_syndicate: "Enviar sindicato",
    update_syndicate: "Actualizar sindicato",
    show_sv_percent: 'Mostrar % de jugadores',
    show_coupon_percent: 'Mostrar % de cupón',
    number_of_rows: 'Número de filas',
    share_of_syndicate: 'Participación del sindicato',
    syndicate_created: 'Sindicato creado',
    syndicate_submitted: 'Sindicato enviado',
    syndicate_updated: 'Sindicato actualizado',
    finished: 'Finalizado',
    started_games: 'Comenzar',
    potential: 'Potencial',
    sort_by_finished: 'Ordenar por completado',
    game_summary: 'Resumen del juego',
    date: 'Fecha',
    league: 'Liga',
    venue: 'Lugar',
    latest_tweets: 'Últimos tweets de',
    weather: 'Reporte del clima',
    temperature: 'Temperatura',
    wind: 'Viento',
    moisture: 'Humedad',
    general_info: 'Información general',
    locale: 'es-ES',
    show: 'Mostrar',
    show_round: 'Ronda de exhibiciones',
    play_on: 'Aprovecharse de',
    today: 'Hoy',
    latest_games: 'Últimos juegos',
    head_to_head: 'Frente a Frente',
    round_information_short: 'Info',
    round_information: 'Información',
    prize_groups: 'Grupos de precios',
    bet: 'Apuesta',
    pro_pick_bets: 'Apuestas de elección profesional',
    help: 'Ayuda',
    round_status_open : "Abrir",
    round_status_voided : "Cancelado",
    round_status_paused : "En pausa",
    round_paused_explanation: 'La ronda está temporalmente en pausa para apostar.',
    jackpot : "Premio mayor estimado",
    after_extra_time: "Después de la prórroga",
    system_guarantee: "Garantía correcta",
    reduced_disclaimer:"En un sistema reducido, se ha reducido el número de filas del sistema matemático correspondiente. Tienes menos posibilidades de ganar, pero el sistema también cuesta menos.",
    show_rows:"Mostrar mis filas",
    api_betting:"Subir archivo",
    select_file:"Seleccione Archivo",
    file:"Archivo",
    id:"Identificación",
    upload:"Subir",
    bet_title:"Apuesta",
    follow_title:"Seguir",
    win_title:"Ganar",
    bet_text:"Elige uno o más resultados en cada uno de los partidos de fútbol de la ronda.",
    follow_text:"Siga sus ganancias en vivo en el sitio",
    win_text:"Si has elegido suficientes resultados correctos, serás uno de los ganadores del grupo.",
    share_of_prizepool: "Parte del premio acumulado",
    register: "Registro",
    fee_per_row: "Tarifa por fila",
    bet_status_open: "Abierto",
    bet_status_won: "Ganado",
    bet_status_lost: "Perdido",
    payout: "Pagar",
    show_details: "Mostrar detalles",
    bet_now: "Apuesta ahora",
    place_more: "Si quieres aumentar tus posibilidades de ganar, puedes elegir más de un resultado en cada juego.",
    active_syndicates: "Sindicatos activos",
    settled_syndicates: "Sindicatos liquidados",
    win_multiplier: "Multiplicador de ganancias",
    win_multiplier_information: "Su apuesta y ganancia potencial se multiplican con el multiplicador de ganancias seleccionado",
    status: "Estado",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
}
