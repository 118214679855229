export const TRANSLATIONS_EN = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "My Bets",
    my_coupons: "My coupons",
    rounds: "Rounds",    
    bet_with_syndicates: "Syndicates",
    
    syndicates: "Syndicates",
    turnover: "Current Pool",
    
    single_rows: "Single rows",
    pro_pick: "Pro pick",
    often: "Win often",
    a_lot: "Win a lot",
    system: "System",
    row_distribution: "Row distribution",
    overview: "Overview",
    full_cover: "Full cover",
    half_cover: "Half cover",
    row_price: "Row price",
    pay: "Place bet",
    
    unsettled_coupons: "Unsettled coupons",
    settled_coupons: "Settled coupons",
    stake: "Stake",
    coupon_share_status_cancelled: "Cancelled",
    your_winnings_right_now: "Your winnings right now",
    correct_rows_now: "Correct picks now",
    starts: "Starts",
    correct_rows: "Correct rows",
    prize: "Prize",
    your_rows: "Your rows",
    profit: "Profit",
    st: "",
    total: "Result",
    rows: "rows",
    row: "row",
    price_per_row: "Price per row",
    total_cost: "Total cost",
    paid: "Paid",
    settled: "Settled",
    channel_internet: "Channel: Internet",
    print: "Print",
    share: "Share",    
    clear_bets: 'Clear Bets',

    
    reduce: "Reduce",
    no_reduced_systems_available: "No reduced systems available",
    round_history: "Round history",
    
    no_profit: "No profit",
    no_coupons: "No Coupons",
    started: "Started at",
    round: "Round",
    winners: "Winners",
    your_bet_is_paid: "Your bet has been placed!",
    do_you_want_to_place_bet: "Do you want to place a bet of",
    
    highest_win: "Highest win",
    insufficient_funds: "Insufficient funds",
    gaming_limits_reached: "Gaming limits reached",
    cancel: "Cancel",
    unable_to_complete_purchase: 'Unable to complete purchase',
    error_code: 'Error code',

    event_goal: 'Goal',
    event_penalty: 'Penalty',
    event_missed_penalty: 'Missed Penalty',
    event_own_goal: 'Own Goal',
    event_var: 'VAR',
    event_yellowcard: 'Yellow Card',
    event_yellowred: 'Second Yellow Card',
    event_substitution: 'Substitution',
    event_redcard: 'Red Card',
    event_pen_shootout_goal: 'Goal from Penalty',
    event_pen_shootout_miss: 'Missed Penalty',
    event_full_time: 'Full Time',
    event_half_time: 'Half Time',
    event_kickoff: 'Kickoff',
    event_finished_after_extra: 'Extra Time Ended',

    out: 'Out',

    
    table: "Table",
    game_info: "Game Info",
    statistics: "Statistics",
        
    team_name: "Team",
    table_abbreviation_num_games: "G",
    table_abbreviation_wins: "W",
    table_abbreviation_draws: "D",
    table_abbreviation_losses: "L",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'Closed',
    full_time: "Full Time",
    round_status_finished : "Finished",
    half_time: 'Half Time',
    
    current_rounds: "Current Rounds",
    settled_rounds: "Settled Rounds",
    no_rounds_available: "No rounds available",

    row_statistics: 'Row statistics',
    
    showing: 'Showing',    
    settings: 'Settings',
    match: 'Match',
    result: 'Result',    
    bet_type: "Bet type",    
    mathematical_system: "Mathematical system",
    reduced_system: "Reduced system",
    no_system: "No system",
    select: "Select",
    is_draw: "Will be drawn with",
    is_drawn: "Drawn",
    draw_disclaimer: 'If subject to draw:',
    num_correct_win: 'Number correct/win',
    more_info: 'More info',
    in_running: 'In Running',
  
    
    go_back: 'Go back',

    to_syndicate: "To syndicate",
    shares: "Shares",
    share_price: "Share price",
    sold_shares: "Sold shares",
    agent_admin: "Agent admin",
    agents: "Agents",
    to_agent: "To agent",
    create_new_syndicate: "Create new syndicate",
    name: "Name",
    target_number_of_shares: "Target number of shares",
    max_number_of_shares: "Max number of shares",
    submit_syndicate: "Submit syndicate",
    update_syndicate: "Update syndicate",
    show_sv_percent: 'Show SW. players %',
    show_coupon_percent: 'Show coupon %',
    number_of_rows: 'Number of rows',
    share_of_syndicate: 'Share of syndicate',
    syndicate_created: 'Syndicate created',
    syndicate_submitted: 'Syndicate submitted',
    syndicate_updated: 'Syndicate updated',
    finished: 'Finished',
    started_games: 'Started',
    potential: 'Potential',
    sort_by_finished: 'Sort by finished',
    game_summary: 'Game summary',
    date: 'Date',
    league: 'League',
    venue: 'Venue',
    latest_tweets: 'Latest tweets from',
    weather: 'Weather',
    temperature: 'Temperature',
    wind: 'Wind',
    moisture: 'Humidity',
    general_info: 'General info',
    locale: 'en-EN',
    show: 'Show',
    show_round: 'Show round',
    play_on: 'Play on',
    today: 'Today',
    latest_games: 'Latest games',
    head_to_head: 'Head to head',
    round_information_short: 'Info',
    round_information: 'Round information',
    prize_groups: 'Prize groups',
    bet: 'Bet',
    pro_pick_bets: 'Pro pick bets',
    help: 'Help',
    round_status_open : "Open",
    round_status_voided : "Cancelled",
    round_status_paused : "Paused",
    round_paused_explanation: 'The round is temporarily paused for betting',
    jackpot : "Estimated Jackpot",
    after_extra_time: "After Extra Time",
    system_guarantee: "correct guarantee",
    reduced_disclaimer:"In a reduced system, the number of rows from the corresponding mathematical system has been reduced. You get a lower chance of winning, but the system also costs less.",
    show_rows:"Show my rows",
    api_betting:"File upload",
    select_file:"Select file",
    file:"File",
    id:"Id",
    upload:"Upload",
    bet_title:"Bet",
    follow_title:"Follow",
    win_title:"Win",
    bet_text:"Pick one or more outcomes in each of the football games of the round",
    follow_text:"Follow your winnings live on the site",
    win_text:"If you have picked enough correct outcomes you will be one of the winners of the pool",
    share_of_prizepool: "Share of prize pool",
    register: "Register",
    fee_per_row: "Fee per row",
    bet_status_open: "Open",
    bet_status_won: "Won",
    bet_status_lost: "Lost",
    payout: "Payout",
    show_details: "Show details",
    bet_now: "Bet now",
    place_more: "If you want to increase your chances of winning you can pick more than one outcome in each game",
    active_syndicates: "active syndicates",
    settled_syndicates: "settled syndicates",
    win_multiplier: "Win multiplier",
    win_multiplier_information: "Your stake and potential win are multiplied with the selected win multiplier",
    status: "Status",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
}
