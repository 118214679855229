// Note: Alternative navigation bar. Most themes uses navbar insted.

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePlayer } from "../player/playerContext";
import { Box, Button, Grid } from '@mui/material';

function LightMenuItem({ id, to, text, selected }) {

    let variant = 'outlined';
    let border = '1px';
    let borderSelected = '1px';
    let textTransform = 'none';
    let backgroundColor = "#FFF";
    let backgroundColorSelected = "#FFD700";
    let hoverBackgroundColor = "#727272";
    let color = "#000";
    let colorSelected = "#000";
    let borderRadius = '5px';
    let fontSize = '12px';
    let fontWeight = 400;
    let margin = '5px';
    let padding = '10px';
    let paddingTop = '6px';
    let paddingBottom = '6px';

    if (window.theme === "tomato") {
        variant = 'text';
        border = '0px, 0px, 1px, 0px';
        borderSelected = '0px, 0px, 2px, 0px';
        textTransform = 'uppercase';
        backgroundColor = "--title-bar-background";
        backgroundColorSelected = "--title-bar-background";
        color = "rgba(0, 0, 0, 0.38)";
        colorSelected = "rgba(0, 0, 0, 0.87)";
        borderRadius = '0px';
        fontSize = '14px';
        fontWeight = 700;
        hoverBackgroundColor = "--title-bar-background";
        margin = '0px';
        padding = '16px';
        paddingTop = '16px';
        paddingBottom = '16px';
    }

    //Creates an event in Google Analytics. Can be used to track user behavior.    
    const reportSelection = (linkId) => {

        let type = "home";

        switch (linkId) {
            case "round-history":
                type = "rounds";
                break;

            case "my-coupons":
                type = "coupons";
                break;

            case "bet-with-syndicates":
                type = "syndicates";
                break;

            case "agent-admin":
                type = "agent_admin";
                break;

            case "api-betting":
                type = "api_betting";
                break;

            case "help":
                type = "help";
                break;

            default:
                break;

        }

        window.gtag("event", "select_content", {
            content_type: type,
            content_id: "light_menu"
        });

    }

    return (
        <Grid item sx={{ margin: margin }}>
            <Link id={id} to={to}>
                <Button onClick={() => reportSelection(id)} variant={variant} sx={
                    {
                        padding: padding,
                        paddingTop: paddingTop,
                        paddingBottom: paddingBottom,
                        gap: '4px',
                        color: selected ? colorSelected : color,
                        height: '30px',
                        textTransform: textTransform,
                        fontWeight: fontWeight,
                        fontSize: fontSize,
                        lineHeight: '18px',
                        borderRadius: borderRadius,
                        border: selected ? borderSelected : border,
                        borderColor: selected ? colorSelected : color,
                        borderStyle: 'solid',
                        backgroundColor: selected ? backgroundColorSelected : backgroundColor,
                        "&:hover": {
                            backgroundColor: hoverBackgroundColor,
                        }
                    }
                }>
                    {text}
                </Button>
                <Box sx={{
                    backgroundColor: selected ? colorSelected : color,
                    height: selected ? '2px' : '1px',
                    transition: 'all 0.2s ease-in-out 0s'
                }} />
            </Link>
        </Grid>
    );
}


function NavbarLight({ whichToUnderline }) {

    const { t } = useTranslation();
    const [player, config] = usePlayer();        
    let agentSyndicateBetting = config && config.agentSyndicateBetting;
    const realMode = player && player._embedded && player._embedded.mode === 'real';
    let apiBetting = config && config.allowCouponFileUpload;

    if (window.theme === "gold" || window.theme === "tomato") {
        apiBetting = false;
    }

    let backGround = 'var(--title-bar-background)';
    let borderColor = '#000000';

    return (

        <Grid container  justifyContent='center' flexDirection= 'var(--flex-direction)'  sx={{ width: '100%', padding: '7px', background: backGround }}>

            <LightMenuItem id="Home" selected={whichToUnderline === '0'} to="/clear" text='Home' borderColor={borderColor} />

            <LightMenuItem id="round-history" selected={whichToUnderline === '4'} to="/roundhistory" text={t('rounds')} borderColor={borderColor} />
            {realMode && (
                <LightMenuItem id="my-coupons" selected={whichToUnderline === '3'} to="/mycoupons" text={t('my_coupons')} borderColor={borderColor} />
            )}            
            {agentSyndicateBetting && (
                <LightMenuItem id="bet-with-syndicates" selected={whichToUnderline === '2'} to="/playagent" text={t('bet_with_syndicates')} borderColor={borderColor} />
            )}            
            {(realMode && apiBetting &&
                <LightMenuItem id="api-betting" selected={whichToUnderline === '7'} to="/apibetting" text={t('api_betting')} borderColor={borderColor} />
            )}

            <LightMenuItem id="help" selected={whichToUnderline === '6'} to="/help" text={t('help')} borderColor={borderColor} />

        </Grid>

    );

}

export default NavbarLight;