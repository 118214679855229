import { Box, Button, Grid, Typography } from "@mui/material";
import { getDateString, getRoundName, getRoundStatus } from "../../../../../common/RoundHelper";
import pridePick13 from '../../../../../images/PridePick13.png';
import pridePick12 from '../../../../../images/PridePick12.png';
import pridePick8 from '../../../../../images/PridePick8.png';
import pridePick13Logo from '../../../../../images/opeimujackpot13.png';
import pridePick12Logo from '../../../../../images/opeimujackpot12.png';
import pridePick8Logo from '../../../../../images/opeimujackpot8.png';
import leoPick13 from '../../../../../images/LeoPick13.png';
import leoPick12 from '../../../../../images/LeoPick12.png';
import leoPick8 from '../../../../../images/LeoPick8.png';
import medina13 from '../../../../../images/medina_13.png';
import medina12 from '../../../../../images/medina_12.png';
import medina8 from '../../../../../images/medina_8.png';
import kung8 from '../../../../../images/kung8.png';
import kung12 from '../../../../../images/kung12.png';
import kung13 from '../../../../../images/kung13.png';

import { formatStringCurrency } from "../../../../../common/CurrencyFormatter";
import { gradientsC, IsLeo, productNamesC, themeC } from "../../../../../theme/themehelper";
import { DbetEightIcon, DbetEightLadder, DbetThirteenIcon, DbetThirteenLadder, DbetTwelveIcon, DbetTwelveLadder } from "../../../../../images/dbeticons";

function CouponHeaderSlim({ jackpot, turnoverAmount, turnoverCurrency, round, t, setShowRoundInfo, setShowProPick }) {

    let roundName = getRoundName(round, t);

    let roundImage = pridePick8;
    let roundLogo = pridePick8Logo;
    let backgroundPosition = "-20px";
    let backgroundInfo = "#FFEE90";
    let borderWidth = "1px";

    let backgroundImageWidth = "145px";
    let backgroundImageHeight = "71px";

    let startDate = new Date(round.openTo);

    let minMaxFlexTextColor = 'var(--text-white)';
    let numberImage = "";
    let numberBackgroundHeight = "64px";
    let numberBackgroundWidth = "77px";
    let fontFamily = 'var(--font-title)';
    let numberIcon = "";

    const isAfterDeadline = startDate < new Date(Date.now());

    if (roundName.indexOf("13") > 0) {
        roundImage = pridePick13;
        roundLogo = pridePick13Logo;
    }

    if (roundName.indexOf("12") > 0) {
        roundImage = pridePick12;
        roundLogo = pridePick12Logo;
    }

    roundImage = `url(${roundImage})`;

    if (window.theme === themeC.Tomato) {
        roundImage = 'linear-gradient(0deg,#17CB6F,#1DAB63)';
        roundLogo = leoPick8;
        backgroundPosition = "0px";
        backgroundInfo = "#FFF";
        borderWidth = "0px";

        if (roundName.indexOf("13") > 0) {
            roundLogo = leoPick13;
            roundImage = 'linear-gradient(0deg,#00A6FF,#0085FF)';
        }

        if (roundName.indexOf("12") > 0) {
            roundLogo = leoPick12;
            roundImage = 'linear-gradient(0deg,#EE44EE,#CC00FF)';
        }
    }

    if (window.theme === themeC.Gold) {
        backgroundImageWidth = "220px";
        backgroundImageHeight = "71px";
    }

    let mixMaxFlex = "";
    let number = "";
    let strokeColor = "";

    if ((window.theme === themeC.Medina || window.theme === themeC.MedinaAr)) {
        roundImage = medina8;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = "Flex";
        number = "8x";
        strokeColor = "linear-gradient(102.58deg, #F37834 1.33%, #F6C55E 277.24%)";

        if (round.name.indexOf("Max") > -1) {
            roundImage = medina13;
            mixMaxFlex = "Max";
            number = "13x";
            strokeColor = "linear-gradient(98.24deg, #F3BA27 2.15%, #F6C55E 101.62%)";
        }

        if (round.name.indexOf("Mix") > -1) {
            roundImage = medina12;
            mixMaxFlex = "Mix";
            number = "12x";
            strokeColor = "linear-gradient(255.21deg, #C7CCF5 13.84%, #93B9DE 112.93%)";
        }
        roundImage = `url(${roundImage})`;
    }

    if (window.theme === themeC.Blue2) {
        roundImage = gradientsC.Blue2.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = "Mini";
        number = "8";
        strokeColor = "none";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";
            roundImage = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";
            roundImage = gradientsC.Blue2.Mix;
        }
    }

    if (window.theme === themeC.Orange) {
        roundImage = gradientsC.Orange.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        strokeColor = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundImage = gradientsC.Orange.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundImage = gradientsC.Orange.Mix;
        }
    }

    if (window.theme === themeC.Wood) {
        roundImage = gradientsC.Wood.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        strokeColor = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundImage = gradientsC.Wood.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundImage = gradientsC.Wood.Mix;
        }
    }

    if (window.theme === themeC.Lion) {
        roundImage = gradientsC.Lion.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        strokeColor = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundImage = gradientsC.Lion.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundImage = gradientsC.Lion.Mix;
        }
    }

    if (window.theme === themeC.Kung) {
        roundImage = gradientsC.Kung.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = round.name.indexOf("Winter") > -1 ? productNamesC.Kung.Winter : productNamesC.Kung.Flex;
        number = "8";
        strokeColor = "none";
        minMaxFlexTextColor = "#27FFAA";
        numberImage = kung8;
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "46px";
        backgroundImageHeight = "90px";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Kung.Max;
            number = "13";
            roundImage = gradientsC.Kung.Max;
            minMaxFlexTextColor = "#34C2FF";
            numberImage = kung13;
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "77px";
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Kung.Mix;
            number = "12";
            roundImage = gradientsC.Kung.Mix;
            minMaxFlexTextColor = "#EE8DFF";
            numberImage = kung12;
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "77px";
        }
        numberImage = `url(${numberImage})`;
    }
    if (window.theme === themeC.Dbet) {
        roundImage = gradientsC.Dbet.Flex;
        roundLogo = "";
        backgroundPosition = "-20px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = round.name.indexOf("Winter") > -1 ? productNamesC.Kung.Winter : productNamesC.Kung.Flex;
        number = "8";
        strokeColor = "none";
        minMaxFlexTextColor = "#F4C004";
        numberImage = "";
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "100px";
        backgroundImageHeight = "90px";
        fontFamily = "Righteous";
        numberIcon =
            <Grid container direction="row" alignItems="center" >
                <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                    {DbetEightIcon()}
                </Grid>
                {DbetEightLadder()}
            </Grid>

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Kung.Max;
            number = "13";
            roundImage = gradientsC.Dbet.Max;
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "130px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                        {DbetThirteenIcon()}
                    </Grid>
                    {DbetThirteenLadder()}
                </Grid>
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Kung.Mix;
            number = "12";
            roundImage = gradientsC.Dbet.Mix;
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "130px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                        {DbetTwelveIcon()}
                    </Grid>
                    {DbetTwelveLadder()}
                </Grid>
        }
        numberImage = `url(${numberImage})`;
    }

    if (window.theme === themeC.Sultan) {
        roundImage = gradientsC.Sultan.Flex;
        roundLogo = "";
        backgroundPosition = "0px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = "Flex";
        number = "8";
        strokeColor = "none";

        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13";
            roundImage = gradientsC.Sultan.Max;
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12";
            roundImage = gradientsC.Sultan.Mix;
        }
    }

    if (window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
        || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
    ) {
        roundImage = gradientsC.Betconstruct.Flex;
        roundLogo = "";
        backgroundPosition = "0px";
        backgroundInfo = "var(--background_4)";
        borderWidth = "0px";
        mixMaxFlex = t('flex');
        if (window.theme === themeC.Cwinz) {
            mixMaxFlex = productNamesC.Cwinz.Flex;
        }
        if (window.theme === themeC.Betbeto) {
            mixMaxFlex = productNamesC.Betbeto.Flex;
        }
        number = "8";
        strokeColor = "none";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = t('max');
            if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Max;
            }
            number = "13";
            roundImage = gradientsC.Betconstruct.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = t('mix');
            if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Mix;
            }
            number = "12";
            roundImage = gradientsC.Betconstruct.Mix;
        }
    }

    const smallText = {
        fontSize: "12px",
        color: "var(--text-black)",
        lineHeight: "18px",
        fontWeight: "400",
        fontFamily: "var(--font-body)",
    };

    const boldText = {
        fontSize: "16px",
        color: "var(--text-black)",
        lineHeight: "24px",
        fontWeight: "700",
        fontFamily: "var(--font-body)",
    };

    const button = {
        padding: '6px 15px',        
        gap: '4px',
        color: 'var(--button-text-color)',        
        textTransform: 'var(--text-transform-button)',
        fontWeight: 'var(--font-weight-button)',
        fontSize: '12px',
        lineHeight: '18px',
        border: borderWidth + ' solid #DADADA',
        borderRadius: '5px',
        backgroundColor: "var(--button-color)",
        "&:hover": {
            backgroundColor: "var(--button-hover-color)",
            border: borderWidth + ' solid #DADADA',
        }
    }

    if(IsLeo(window.theme)){
        button.borderRadius = "var(--button-border-radius)";
        button.padding = "12px 30px";
        button.fontFamily = "var(--font-button)";
        button.fontSize = "14px";
    }

    const setPro = (show) => {
        setShowProPick(show);
        if (show) {
            setShowRoundInfo(false);
        }
    }

    const setRound = (show) => {
        setShowRoundInfo(show);
        if (show) {
            setShowProPick(false);
        }
    }

    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item sx={{
                padding: '15px', width: '100%',
                backgroundImage: roundImage,
                backgroundSize: 'cover',
                backgroundPositionY: backgroundPosition,
                borderRadius: 'var(--radius)',
            }}>
                <Grid container direction='column' alignItems='center'                    >
                    <Grid item sx={{
                        width: backgroundImageWidth,
                        height: backgroundImageHeight,
                        backgroundImage: `url(${roundLogo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        {(window.theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "10px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: "monumentextended",
                                    color: 'var(--text-black)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: "monumentextended",
                                    background: strokeColor,
                                    backgroundClip: "text",
                                    color: "transparent",
                                    display: "inline-block",
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                        {(window.theme === themeC.Blue2 || window.theme === themeC.Sultan || IsLeo(window.theme)) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px" }}>
                                <Typography sx={{
                                    fontSize: "18px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "60px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "800",
                                    marginTop: "-17px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }

                        {(window.theme === themeC.Kung || window.theme === themeC.Dbet) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px" }}>
                                <Typography sx={{
                                    fontSize: "20px",
                                    fontFamily: fontFamily,
                                    color: minMaxFlexTextColor,
                                    fontWeight: window.theme === themeC.Kung ? "700" : "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundImage: numberImage,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        height: numberBackgroundHeight,
                                        width: numberBackgroundWidth,
                                    }}
                                >
                                    {numberIcon}
                                </Box>
                            </Grid>
                        }

                        {(window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit
                            || window.theme === themeC.Metro || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px" }}>
                                <Typography sx={{
                                    fontSize: "18px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "60px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "800",
                                    marginTop: "-17px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' alignItems='center' sx={{ padding: '10px', background: backgroundInfo, width: '100%' }}>
                {!isAfterDeadline &&
                    <Grid item>
                        <Grid container direction="column" alignItems='center' sx={{ marginTop: '10px', marginBottom: '0px' }}>
                            <Typography sx={smallText}>{t('jackpot')}</Typography>
                            <Typography sx={boldText}>{formatStringCurrency(jackpot, turnoverCurrency, t, true)}</Typography>
                        </Grid>
                    </Grid>
                }
                {isAfterDeadline &&
                    <Grid item>
                        <Grid container direction="column" alignItems='center' sx={{ marginTop: '10px', marginBottom: '0px' }}>
                            <Typography sx={smallText}>{t('status')}</Typography>
                            <Typography sx={boldText}>{getRoundStatus(round, startDate, t)}</Typography>
                        </Grid>
                    </Grid>
                }
                <Grid container justifyContent='space-evenly' sx={{ marginTop: '5px' }}>
                    <Grid item>
                        <Grid container direction='column' alignItems='center'>
                            <Typography sx={smallText}>{t('turnover')}</Typography>
                            <Typography sx={boldText}>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</Typography>
                            {!isAfterDeadline &&
                                <Grid sx={{ marginTop: '10px', marginBottom: '0px' }}>
                                    <Button variant="outlined" onClick={() => setRound(true)} sx={button}>
                                        {t('game_info')}
                                    </Button>
                                </Grid>
                            }

                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='column' alignItems='center'>
                            <Typography sx={smallText}>{isAfterDeadline ? t('started') : t('starts')}</Typography>
                            <Typography sx={boldText}>{getDateString(round, t)}</Typography>
                            {!isAfterDeadline &&
                                <Grid sx={{ marginTop: '10px', marginBottom: '0px' }}>
                                    <Button variant="outlined" onClick={() => setPro(true)} sx={button}>
                                        {t('pro_pick')}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>

    );
}

export default CouponHeaderSlim;