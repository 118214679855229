import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { Button } from '../reusable-components';
import { operatorAdviceReload } from '../App';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }   

    static getDerivedStateFromError(error) {
        // Update state to show fallback UI when an error occurs
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an error tracking service or console
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        const { t } = this.props
        if (this.state.hasError) {                       
            // Render your custom error page UI
            return (
                <Grid container
                    justifyContent="flex-start"                    
                    direction="column"
                    minHeight="800px"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',                        
                        textAlign: 'center',
                        background: 'var(--background_1)',
                        paddingTop:"100px"                        
                    }}
                >
                    <Box>
                        <Typography sx={{ fontFamily: 'var(--font-title)', fontSize: "18px" }} gutterBottom>
                            {t('error_title')}
                        </Typography>
                        <Typography sx={{ fontFamily: 'var(--font-body)', fontSize: "14px" }} paragraph>
                            {t('error_text')}                            
                        </Typography>
                        <Button onClick={() => operatorAdviceReload()}>
                            {t('reload')}  
                        </Button>
                    </Box>
                </Grid>
            );
        }

        // Render children if no error
        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary);