export const TRANSLATIONS_NO = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "Mine spill",
    my_coupons: "Mine kuponger",
    rounds: "Runder",    
    bet_with_syndicates: "Andelsbank",
    
    syndicates: "Andelsspill",
    turnover: "Omsetning ",
    
    single_rows: "Enkeltrader",
    pro_pick: "Hurtigspill",
    often: "Vinn ofte",
    a_lot: "Vinn mye",
    system: "System",
    row_distribution: "Radfordeling",
    overview: "Oversikt",
    full_cover: "Helgarderinger",
    half_cover: "Halvgarderinger",
    row_price: "Radpris",
    pay: "Betal",
    
    unsettled_coupons: "Pågående kuponger",
    settled_coupons: "Avgjorte kuponger",
    stake: "Innsats",
    coupon_share_status_cancelled: "Kansellerte",
    your_winnings_right_now: "Din vinster akkurat nå",
    correct_rows_now: "Antall rette nå",
    starts: "Starter",
    correct_rows: "Antall rette",
    prize: "Premie",
    your_rows: "Dine rader",
    profit: "Profitt",
    st: "stk",
    total: "Resultat",
    rows: "Rader",
    row: "Rad",
    price_per_row: "Pris per rad",
    total_cost: "Total innsats",
    paid: "Betalt",
    settled: "Avgjort",
    channel_internet: "Spilt via: internett",
    print: "Skriv ut",
    share: "Del",
    
    reduce: "Redusere",
    no_reduced_systems_available: "Ingen reduserte systemer tilgjengelig ",
    round_history: "Rundehistorie",
    
    no_profit: "Ingen vinst",
    no_coupons: "Ingen kuponger",
    started: "Startet",
    round: "Runde",
    winners: "Vinnere",
    your_bet_is_paid: "Ditt spill er betalt",
    do_you_want_to_place_bet: "Vil du plassere et spill ",
    
    highest_win: "Høyeste vinst",
    insufficient_funds: "For lav saldo",
    cancel: "Avbryt",
    unable_to_complete_purchase: "Kunne ikke gjennomføre kjøpet",
    error_code: "Feilkode",
   
    event_goal: "Mål",
    event_penalty: "Straffe",
    event_missed_penalty: "Bommet straffe",
    event_own_goal: "Selvmål",
    event_var: "VAR",
    event_yellowcard: "Gult kort",
    event_yellowred: "Andre gule kort",
    event_substitution: "Bytte",
    event_redcard: "Rødt kort",
    event_pen_shootout_goal: "Mål fra straffe",
    event_pen_shootout_miss: "Bommet straffe",
    event_full_time: "Fulltid",
    event_half_time: "Pause",
    event_kickoff: "Avspark",
    event_finished_after_extra: "Tilleggstid slutt",
    out: "Ut",
    
    table: "Tabell",
    game_info: "Matchinfo",
    statistics: "Statistikk",
    
    in_running: "Live",
    full_time: "Fulltid",
    round_status_finished: "Kampen er over",
    half_time: "Pause",
    
    
    team_name: "Lag",
    table_abbreviation_num_games: "M",
    table_abbreviation_wins: "V",
    table_abbreviation_draws: "U",
    table_abbreviation_losses: "T",
    table_abbreviation_points: "P",
    current_rounds: "Aktuelle runder",
    settled_rounds: "Avgjorte runder",
    no_rounds_available: "Ingen tilgjengelige runder",
    row_statistics: "Radstatistikk",
    
    showing: "Viser",
    settings: "Innstillinger",
    match: "Matchinfo",
    result: "Resultat",
    bet_type: "Systemtype",
    mathematical_system: "Matematisk system",
    reduced_system: "Redusert system",
    no_system: "Ingen system",
    select: "Velg",
    is_draw: "Trekning med",
    is_drawn: "Trukket",
    draw_disclaimer: "Hvis trekning:",
    num_correct_win: "Antall rett/vinst",
    more_info: "Mer informasjon",
    clear_bets: "Tøm kupong",
  
    go_back: "Tilbake",
    to_syndicate: "Til andelsspill",
    shares: "Andeler",
    share_price: "Andelspris",
    sold_shares: "Solgte andeler",
    agent_admin: "Andeler admin",
    agents: "Andeler",
    to_agent: "Til andeler",
    create_new_syndicate: "Lag nytt andelsspill",
    name: "Navn",
    target_number_of_shares: "Mål antall andeler",
    max_number_of_shares: "Maksimalt antall andeler",
    submit_syndicate: "Legg inn syndikat",
    update_syndicate: "Oppdater syndikat",
    show_sv_percent: "Vis pool prosent",
    show_coupon_percent: "Vis kupongprosent",
    number_of_rows: "Antall rader",
    share_of_syndicate: "Andel av andelsspill",
    syndicate_created: "Andelsspill opprettet",
    syndicate_submitted: "Andelsspill sendt inn",
    syndicate_updated: "Andelsspill oppdatert",
    finished: "Avsluttet",
    started_games: "Startet",
    potential: "Potensiell",
    sort_by_finished: "Sort etter avsluttede",
    game_summary: "Matchoppsummering",
    date: "Dato",
    league: "Liga",
    venue: "Arena",
    latest_tweets: "Nylige tweets fra",
    weather: "Været",
    temperature: "Temperatur",
    wind: "Vind",
    moisture: "Fuktighet",
    general_info: "Generell informasjon",
    locale: "nb-NO",
    show: "Vis",
    show_round: "Vis runde",
    play_on: "Spill på",
    today: "I dag",
    latest_games: "Siste spill",
    head_to_head: "Head to head",
    round_information_short: "Informasjon",
    round_information: "Rundeinformasjon",
    prize_groups: "Vinstgrupper",
    bet: "Vis",
    pro_pick_bets: "Hurtigspill",
    help: "Hjelp",
    round_status_open: "Åpen",
    round_status_voided: "Kansellert",
    round_status_paused: "Pauset",
    round_active_after_deadline: "Stengt",
    round_paused_explanation: "Runden er pauset for betting foreløpig",
    jackpot: "Estimert Jackpot",
    after_extra_time: "Etter tilleggstid",
    system_guarantee: "Systemgaranti",
    reduced_disclaimer: "I et redusert system er antall rader fra det tilsvarende matematiske systemet redusert. Du får mindre sjanse til å vinne, men systemet koster også mindre.",
    show_rows: "Vis mine rader",
    api_betting: "Filopplastning ",
    select_file: "Velg fil",
    file: "Filopplastning ",
    id: "ID",
    upload:"Laste opp",
    bet_title:"Tipp",
    follow_title:"Følg",
    win_title:"Vinn",
    bet_text:"Velg ett eller flere utfall i rundens kamper",
    follow_text:"Følg vinnersjansene dine live på siden",
    win_text:"Får du nok riktige kamputfall er du med og deler potten",
    share_of_prizepool: "Premiepottandel",
    register: "Bli kunde",
    fee_per_row: "Fee per row",
    bet_status_open: "Open",
    bet_status_won: "Won",
    bet_status_lost: "Lost",
    payout: "Payout",
    show_details: "Show details",
    bet_now: "Spill",
    place_more: "If you want to increase your chances of winning you can pick more than one outcome in each game",
    active_syndicates: "pågående andelsspill",
    settled_syndicates: "avgjorte andelsspill",
    win_multiplier: "Vinn multiplikator",
    win_multiplier_information: "Din innsats og potensielle gevinst multipliseres med den valgte gevinstmultiplikatoren",
    balance: "Balance",

    join_title:"Bli med",
    join_text:"Ta del i et større system sammen med andre spillere og øk sjansene dine for å vinne jackpotten.",
    valid_title:"Alltid gyldig",
    valid_text:"Din innsats er alltid gyldig, selv om andelsspillet ikke er utsolgt.",
    win_together_title:"Vinn sammen",
    win_together_text:"Premien fra andelsspillet deles mellom andelerne.",
    fee:"Avgift",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
}
