export const TRANSLATIONS_AR = {
    max: "أقصى",
    mix: "خلط",
    flex: "فليكس",
    
    my_bets: "رهاناتي",
    my_coupons: "كوبوناتي",
    rounds: "جولات",    
    bet_with_syndicates: "النقابات",
    
    syndicates: "النقابات",
    turnover: "التجمع الحالي",
    
    single_rows: "صفوف مفردة",
    pro_pick: "اختيار المحترفين",
    often: "اربح كثيرا",
    a_lot: "اربح كثيرا",
    system: "نظام",
    row_distribution: "توزيع الصفوف",
    overview: "نظرة عامة",
    full_cover: "غطاء كامل",
    half_cover: "نصف غطاء",
    row_price: "سعر الصف",
    pay: "ضع الرهان",
    
    unsettled_coupons: "كوبونات غير مستقرة",
    settled_coupons: "كوبونات التسوية",
    stake: "رهان",
    coupon_share_status_cancelled: "الغاء",
    your_winnings_right_now: "أرباحك الآن",
    correct_rows_now: "اللقطات الصحيحة الآن",
    starts: "يبدأ",
    correct_rows: "تصحيح الصفوف",
    prize: "جائزة",
    your_rows: "صفوفك",
    profit: "ربح",
    st: "",
    total: "نتيجة",
    rows: "الصفوف",
    row: "صف",
    price_per_row: "السعر لكل صف",
    total_cost: "التكلفة الإجمالية",
    paid: "مدفوع",
    settled: "تسوية",
    channel_internet: "قناة: انترنت",
    print: "طبع",
    share: "شارك",    
    clear_bets: 'رهانات واضحة',

    
    reduce: "تقليل",
    no_reduced_systems_available: "لا توجد أنظمة مخفضة متاحة",
    round_history: "تاريخ الجولة",
    
    no_profit: "لا ربح",
    no_coupons: "لا كوبونات",
    started: "بدأت في",
    round: "جولة",
    winners: "الفائزون",
    your_bet_is_paid: "تم وضع رهانك!",
    do_you_want_to_place_bet: "هل تريد وضع رهان على",
    
    highest_win: "أعلى فوز",
    insufficient_funds: "أموال غير كافية",
    cancel: "إلغاء",
    unable_to_complete_purchase: 'غير قادر على إكمال عملية الشراء',
    error_code: 'رمز الخطأ',

    event_goal: 'هدف',
    event_penalty: 'ركلة جزاء ',
    event_missed_penalty: 'ركلة جزاء ضائعة',
    event_own_goal: 'هدف في مرماه',
    event_var: 'حكم الفيديو المساعد',
    event_yellowcard: 'بطاقة صفراء',
    event_yellowred: 'البطاقة الصفراء الثانية',
    event_substitution: 'استبدال',
    event_redcard: 'بطاقة حمراء',
    event_pen_shootout_goal: 'هدف من ركلة جزاء',
    event_pen_shootout_miss: 'ركلة جزاء ضائعة',
    event_full_time: 'وقت كامل',
    event_half_time: ' نصف الوقت',
    event_kickoff: 'انطلاق',
    event_finished_after_extra: 'انتهى الوقت الإضافي',

    out: 'خارج',

    
    table: "جدول",
    game_info: "معلومات اللعبة",
    statistics: "الاحصاءات",
        
    team_name: "فريق",
    table_abbreviation_num_games: "G",
    table_abbreviation_wins: "W",
    table_abbreviation_draws: "D",
    table_abbreviation_losses: "L",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'مغلق',
    full_time: "وقت كامل",
    round_status_finished : "انتهي",
    half_time: 'نصف الوقت',
    
    current_rounds: "الجولات الحالية",
    settled_rounds: "جولات التسوية",
    no_rounds_available: "لا توجد جولات متاحة",

    row_statistics: 'إحصائيات الصف',
    
    showing: 'عرض',    
    settings: 'اعدادات',
    match: 'مباراة',
    result: 'نتيجة',    
    bet_type: "نوع الرهان",    
    mathematical_system: "النظام الرياضي",
    reduced_system: "نظام مخفض",
    no_system: "لا يوجد نظام",
    select: "اختيار",
    is_draw: "سيتم رسمها مع",
    is_drawn: "تعادل",
    draw_disclaimer: 'إذا كان خاضعا للسحب:',
    num_correct_win: 'الرقم الصحيح / الفوز',
    more_info: 'معلومات اكثر',
    in_running: 'قيد التشغيل',
  
    
    go_back: 'الرجوع للخلف',

    to_syndicate: "إلى النقابة",
    shares: "تشارك",
    share_price: "سعر السهم",
    sold_shares: "الأسهم المباعة",
    agent_admin: "مسؤول الوكيل",
    agents: "الوكلاء",
    to_agent: "إلى الوكيل",
    create_new_syndicate: "إنشاء نقابة جديدة",
    name: "اسم",
    target_number_of_shares: "العدد المستهدف من الأسهم",
    max_number_of_shares: "الحد الأقصى لعدد الأسهم",
    submit_syndicate: "تقديم النقابة",
    update_syndicate: "تحديث النقابة",
    show_sv_percent: 'إظهار لاعبي ٪',
    show_coupon_percent: 'اظهر الكوبون ٪',
    number_of_rows: 'عدد الصفوف',
    share_of_syndicate: 'حصة النقابة',
    syndicate_created: 'إنشاء نقابة',
    syndicate_submitted: 'النقابة المقدمة',
    syndicate_updated: 'تحديث النقابة',
    finished: 'انتهاء',
    started_games: 'بدأت',
    potential: 'الإمكانية',
    sort_by_finished: 'فرز حسب الانتهاء',
    game_summary: 'ملخص اللعبة',
    date: 'تاريخ',
    league: 'دوري',
    venue: 'موقع',
    latest_tweets: 'آخر التغريدات من',
    weather: 'طقس',
    temperature: 'درجة الحرارة',
    wind: 'ريح',
    moisture: 'رطوبة',
    general_info: 'معلومات عامة',
    locale: 'ar-MA',
    show: 'عرض',
    show_round: 'جولة العرض',
    play_on: 'العب على',
    today: 'اليوم',
    latest_games: 'أحدث الألعاب',
    head_to_head: 'وجها لوجه',
    round_information_short: 'معلومات',
    round_information: 'معلومات مستديرة',
    prize_groups: 'مجموعات الجوائز',
    bet: 'راهن',
    pro_pick_bets: 'الرهانات اختيار المحترفين',
    help: 'مساعدة',
    round_status_open : "مفتوح",
    round_status_voided : "الغاء",
    round_status_paused : "توقف",
    round_paused_explanation: 'تم إيقاف الجولة مؤقتا للمراهنة',
    jackpot : "الجائزة الكبرى المقدرة",
    after_extra_time: "بعد وقت إضافي",
    system_guarantee: "الضمان الصحيح",
    reduced_disclaimer:"في نظام مخفض ، تم تقليل عدد الصفوف من النظام الرياضي المقابل. تحصل على فرصة أقل للفوز ، لكن النظام يكلف أيضا أقل.",
    show_rows:"إظهار الصفوف الخاصة بي",
    api_betting:"تحميل الملف",
    select_file:"حدد الملف",
    file:"ملف",
    id:"معرف",
    upload:"رفع",
    bet_title:"راهن",
    follow_title:"اتبع",
    win_title:"فوز",
    bet_text:"اختر نتيجة واحدة أو أكثر في كل مباراة من مباريات كرة القدم في الجولة",
    follow_text:"تابع أرباحك مباشرة على الموقع",
    win_text:"إذا كنت قد اخترت ما يكفي من النتائج الصحيحة ، فستكون أحد الفائزين في المجموعة",
    share_of_prizepool: "حصة مجموع الجوائز",
    register: "سجل",
    fee_per_row: "الرسوم لكل صف",
    bet_status_open: "مفتوح",
    bet_status_won: "فاز",
    bet_status_lost: "مفقود",
    payout: "دفع تعويضات",
    show_details: "اظهر التفاصيل",
    bet_now: "راهن الآن",
    place_more: "إذا كنت ترغب في زيادة فرصك في الفوز ، يمكنك اختيار أكثر من نتيجة واحدة في كل لعبة",
    active_syndicates: "النقابات النشطة",
    settled_syndicates: "settled syndicates",
    win_multiplier: "فوز مضاعف",
    win_multiplier_information: "يتم مضاعفة حصتك وربحك المحتمل بمضاعف الفوز المحدد",
    status: "حالة",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
}
