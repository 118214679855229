import React, { useEffect, useRef } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
    Redirect
} from "react-router-dom";

import { AuthProvider } from "./auth/authContext";
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ApiProvider } from "./api/apiContext";
import { PlayerProvider } from "./player/playerContext";
import LandingPage from './landing/landing';
import RoutedRound from './routedround';
import CouponView from './couponview';
import RoundHistory from './roundhistory/roundhistory';
import "./translations/i18n";
import Syndicate from './syndicates/syndicate';
import AdvancedCouponView from './advancedcouponview';
import CouponShareList from './couponsharelist/couponsharelist';
import Help from './help/help';
import ApiBetting from './apibetting';
import { ThemeProvider, createTheme } from '@mui/material';
import { getTheme } from './theme/themehelper';
import PlayAgent from './syndicates/playagent';
import { getLanguage } from './translations/languagehelper';
import { initTheme } from './theme/inittheme';
import { operatorC } from "./common/operatorhelpers";
import i18n from "i18next";
import ErrorBoundary from './common/ErrorBoundary';

const POOLX_MESSAGE_SOURCE = 'poolx_game_client';
const OPERATOR_MESSAGE_SCROLL_TO_TOP = 'scroll_to_top';

const OPERATOR_MESSAGE_SCROLL_TO_TOP_DEF_DELAY = 200
const OPERATOR_MESSAGE_BET_SUCCESS_DEF_DELAY = 200;
const OPERATOR_MESSAGE_CONTENT_RESIZE_DEF_DELAY = 200;
const OPERATOR_MESSAGE_ERROR_DEF_DELAY = 200;

let useExtendedNoticeFormat = false;

export const createMessageId = () => {
    return window.performance.timeOrigin + window.performance.now();
}

export const operatorAdviceScrollToTop = (delay) => {
    // advise operator that it is desirable to scroll the page to the top
    let operatorMessage = OPERATOR_MESSAGE_SCROLL_TO_TOP;
    let targetOrigin = '*';
    if (useExtendedNoticeFormat) {
        targetOrigin = '*';
        operatorMessage = {
            source: POOLX_MESSAGE_SOURCE,
            level: "action",
            message_type: "scroll_to_top",
            message_id: createMessageId(),
        };
    }

    window.parent.postMessage(operatorMessage, targetOrigin);

    if (delay && delay <= 0) {
        // Explicitly negative delay indicate no resend
        return
    }
    let actualDelay = OPERATOR_MESSAGE_SCROLL_TO_TOP_DEF_DELAY;
    if (delay) {
        actualDelay = delay
    }
    //console.debug('sending scroll to top(' + actualDelay + ')');
    setTimeout(() => {
        window.parent.postMessage(operatorMessage, targetOrigin);
    }, actualDelay)
}

export const operatorAdviceBetSuccess = (data, delay) => {
    let operatorMessage = data;
    let targetOrigin = '*';
    if (useExtendedNoticeFormat) {
        targetOrigin = '*';
        operatorMessage = {
            source: POOLX_MESSAGE_SOURCE,
            level: "notice",
            message_type: "bet_placed",
            message_id: createMessageId(),
            payload: {
                amount: data.amount,
                currency: data.currency,
                transactionId: data.transactionId,
                roundId: data.roundId,
            }
        };
    }
    //console.debug("posting 'bet_success' message to the parent window", operatorMessage, targetOrigin)
    window.parent.postMessage(operatorMessage, targetOrigin);

    if (delay && delay <= 0) {
        // Explicitly negative delay indicate no resend
        return
    }
    let actualDelay = OPERATOR_MESSAGE_BET_SUCCESS_DEF_DELAY;
    if (delay) {
        actualDelay = delay
    }

    setTimeout(() => {
        window.parent.postMessage(operatorMessage, targetOrigin);
    }, actualDelay)
}

export const operatorAdviceContentResize = (data, delay) => {
    let operatorMessage = data;
    let targetOrigin = '*';
    if (useExtendedNoticeFormat) {
        targetOrigin = '*';
        operatorMessage = {
            source: POOLX_MESSAGE_SOURCE,
            level: "action",
            message_type: "resize_content",
            message_id: createMessageId(),
            payload: {
                width: data.width,
                height: data.height,
                units: data.units,
            }
        };
    }
    //console.info("posting 'resize' message to the parent window", data)
    window.parent.postMessage(operatorMessage, targetOrigin);

    if (delay && delay <= 0) {
        // Explicitly negative delay indicate no resend
        return
    }
    let actualDelay = OPERATOR_MESSAGE_CONTENT_RESIZE_DEF_DELAY;
    if (delay) {
        actualDelay = delay
    }

    setTimeout(() => {
        window.parent.postMessage(operatorMessage, targetOrigin);
    }, actualDelay)
}

export const operatorAdviceErrorOccurred = (data, delay) => {
    let operatorMessage = data;
    let targetOrigin = '*';
    if (useExtendedNoticeFormat) {
        targetOrigin = '*';
        operatorMessage = {
            source: POOLX_MESSAGE_SOURCE,
            level: "notice",
            message_type: "error_occurred",
            message_id: createMessageId(),
            payload: {
                error_code: data.errorCode,
                error_message: data.errorMessage,
                operator_error_code: data.operatorErrorCode,
            }
        };
    }
    window.parent.postMessage(operatorMessage, targetOrigin);

    if (delay && delay <= 0) {
        // Explicitly negative delay indicate no resend
        return
    }
    let actualDelay = OPERATOR_MESSAGE_ERROR_DEF_DELAY;
    if (delay) {
        actualDelay = delay
    }

    setTimeout(() => {
        window.parent.postMessage(operatorMessage, targetOrigin);
    }, actualDelay)
}

export const operatorAdviceReload = () => {
    let operatorMessage = "reload_client";
    let targetOrigin = '*';
    if (useExtendedNoticeFormat) {
        targetOrigin = '*';
        operatorMessage = {
            source: POOLX_MESSAGE_SOURCE,
            level: "notice",
            message_type: "reload_client",
            message_id: createMessageId(),
            payload: {
            }
        };
    }
    window.parent.postMessage(operatorMessage, targetOrigin);
    let actualDelay = OPERATOR_MESSAGE_ERROR_DEF_DELAY;

    setTimeout(() => {
        window.parent.postMessage(operatorMessage, targetOrigin);
    }, actualDelay)
}

const handleRouteChange = () => {
    //console.debug('handleRouteChange calling scroll to top');
    operatorAdviceScrollToTop(500);
}

// improvised component to hook into router context
const RouteSwitchListener = () => {
    let location = useLocation();
    useEffect(() => {
        handleRouteChange(location);
    }, [location])

    return <div />
}

function App() {
    const urlParams = new URLSearchParams(window.location.search);
    let languageSetTo = 'en';
    if (urlParams.size > 0) {
        languageSetTo = getLanguage(urlParams);
        i18n.changeLanguage(languageSetTo);
    }

    let operatorId
    let operatorToken
    let operatorGameId
    let operatorPlayerId
    let operatorCurrency
    let operatorLanguage
    let operatorChannel
    let operatorJurisdiction
    let operatorExtraData
    let homeUrl
    let roundId
    let syndicateId

    let navigationKey = urlParams.get("navigationKey");
    let navigationId = urlParams.get("navigationId");

    switch (navigationKey) {
        case "round":
            roundId = navigationId;
            break;
        case "syndicate":
            syndicateId = navigationId;
            break;
        default:
            break;
    }


    operatorId = urlParams.get("operatorId");
    let compatMode = operatorId && (operatorId === operatorC.Mockop || operatorId === operatorC.EuroOperator);
    if (compatMode) {
        operatorId = urlParams.get("operatorId");
        operatorToken = urlParams.get("operatorToken");
        operatorPlayerId = urlParams.get("operatorPlayerId");
        homeUrl = urlParams.get('homeUrl');
        operatorExtraData = urlParams.get("extraData");
    } else {
        operatorId = urlParams.get("operatorid");
        operatorToken = urlParams.get("token");
        operatorGameId = urlParams.get("gameid");
        operatorPlayerId = urlParams.get("playerid");
        operatorCurrency = urlParams.get("currency");
        operatorLanguage = urlParams.get("language");
        operatorChannel = urlParams.get("channel");
        operatorJurisdiction = urlParams.get("jurisdiction");
    }


    if (!operatorGameId) {
        operatorGameId = 'poolx';
    }
    if (!operatorPlayerId) {
        operatorPlayerId = '';
    }
    if (!operatorCurrency) {
        operatorCurrency = '';
    }
    if (!operatorLanguage) {
        operatorLanguage = '';
    }
    if (!operatorChannel) {
        operatorChannel = 'online';
    }
    if (!operatorJurisdiction) {
        operatorJurisdiction = '';
    }
    if (!operatorExtraData) {
        operatorExtraData = '';
    }
    if (!homeUrl) {
        homeUrl = '';
    }

    // TODO find a proper way to do this
    if (operatorId && (operatorId === "EXPEKTSE" || operatorId === "BETMGMSE" || operatorId === "LEOVEGASSE")) {
        useExtendedNoticeFormat = true;
    }

    let pTheme = 'light';

    if (urlParams.size !== 0) {
        pTheme = getTheme(urlParams, languageSetTo, operatorId)
        initTheme(pTheme);
        window.operatorId = operatorId;
        window.theme = pTheme;
    }

    const theme = createTheme({
        name: pTheme,
        components: {
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: 'var(--font-body)',
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontFamily: 'var(--font-body)',
                        fontSize: "12px"
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#2196F3',
            },
            secondary: {
                main: '#FFC107',
            },
        },
    });

    const appRef = useRef(null)
    // Posting resize messages to the parent window for operators that don't want to use 'iframe-resizer`
    useEffect(() => {
        const element = appRef?.current;
        if (!element) return;

        const observer = new ResizeObserver((entries) => {
            // Do something when the element is resized
            const width = entries[0]?.contentBoxSize[0]?.inlineSize;
            const height = entries[0]?.contentBoxSize[0]?.blockSize;
            if (width || height) {
                let contentResizeEvent = {
                    type: "content_resize",
                    height: height,
                    width: width,
                    units: "px",
                }
                operatorAdviceContentResize(contentResizeEvent)
            }
        });

        observer.observe(element);
        return () => {
            // Cleanup the observer by unobserving all elements
            observer.disconnect();
        };
    }, [])

    const onAuthError = (response) => {
        let errorCode = 101;
        let operatorErrorCode = 0;

        if (response.errorCode !== undefined) {
            errorCode = response.errorCode;
        }

        if (response.operatorErrorCode !== undefined) {
            operatorErrorCode = response.operatorErrorCode;
        }

        operatorAdviceErrorOccurred({
            errorCode: errorCode,
            errorMessage: "Your session has expired",
            operatorErrorCode: operatorErrorCode
        })

    }


    return (
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <ReactQueryCacheProvider queryCache={new QueryCache()}>

                    <AuthProvider
                        operatorId={operatorId}
                        operatorGameId={operatorGameId}
                        operatorPlayerId={operatorPlayerId}
                        operatorChannel={operatorChannel}
                        operatorLanguage={operatorLanguage}
                        operatorToken={operatorToken}
                        operatorCurrency={operatorCurrency}
                        operatorJurisdiction={operatorJurisdiction}
                        operatorExtraData={operatorExtraData}
                        homeUrl={homeUrl}
                        onAuthError={onAuthError}
                    >
                        <ApiProvider>
                            <PlayerProvider>
                                <div className="wrapper" ref={appRef}>
                                    <Router>

                                        <RouteSwitchListener />
                                        <Switch>
                                            <Route path={"/apibetting"}>
                                                <ApiBetting />
                                            </Route>
                                            <Route path="/:tipsId/rounds/:roundId">
                                                <RoutedRound />
                                            </Route>
                                            <Route path="/rounds/:roundId">
                                                <RoutedRound />
                                            </Route>
                                            <Route path="/mycoupons">
                                                <CouponShareList />
                                            </Route>
                                            <Route path="/roundhistory">
                                                <RoundHistory />
                                            </Route>
                                            <Route path="/coupon/:couponId">
                                                <CouponView />
                                            </Route>
                                            <Route path="/playagent">
                                                <PlayAgent agentId={0} />
                                            </Route>
                                            <Route path="/advancedCouponView/:couponShareId">
                                                <AdvancedCouponView />
                                            </Route>
                                            <Route path="/syndicate/:syndicateId">
                                                <Syndicate />
                                            </Route>
                                            <Route path="/syndicatesforagent/:agentId"
                                                render={(props) => {
                                                    return (
                                                        <PlayAgent agentId={props.match.params.agentId} />
                                                    )
                                                }}>
                                            </Route>
                                            <Route path="/help">
                                                <Help />
                                            </Route>
                                            <Route
                                                path="/clear"
                                                render={() => {
                                                    roundId = undefined;
                                                    syndicateId = undefined;
                                                    return (
                                                        <LandingPage />
                                                    )
                                                }}
                                            />
                                            <Route
                                                path="/"
                                                render={() => {

                                                    if (syndicateId) {
                                                        return (
                                                            <Redirect to={"/syndicate/" + syndicateId} />
                                                        );
                                                    }

                                                    return (
                                                        roundId ?
                                                            <Redirect to={"/rounds/" + roundId} /> :
                                                            <LandingPage />
                                                    )
                                                }}
                                            />

                                        </Switch>
                                    </Router>
                                </div>
                            </PlayerProvider>
                        </ApiProvider>
                    </AuthProvider>

                </ReactQueryCacheProvider>
            </ThemeProvider>
        </ErrorBoundary>

    );
}

export default App;
