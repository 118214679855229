import { React, useState } from 'react';
import NavBar from '../../navbar';
import { SyndicateWrapper } from './styles';
import { H2, Button } from '../../reusable-components';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import useSyndicates from '../useSyndicates';
import useDetailRound from '../../rounds/useDetailRound';
import SyndicateConfirm from '../syndicateconfirm';
import Moment from "react-moment";
import { usePlayer } from "../../player/playerContext";
import { Redirect } from "react-router-dom";
import { getReducedFiendlyName, getRoundName } from '../../common/RoundHelper';
import { PoolXNumberFormat } from '../../common/CurrencyFormatter';
import SyndicateCoupon from '../syndicatecoupon';
import { ClickAwayListener, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Syndicate() {
  const { syndicateId } = useParams();
  const { t } = useTranslation();
  const [showConfirm, setShowConfirm] = useState(false);
  const [player, config] = usePlayer();

  const [syndicates] = useSyndicates();
  const syndicate = syndicates?.filter(({ id }) => id === syndicateId)[0];
  const { round } = useDetailRound(syndicate?.roundId);

  const [stakeMultiplierTooltipOpen, setStakeMultiplierTooltipOpen] = useState(false);

  const handleStakeMultiplierTooltipClose = () => {
    setStakeMultiplierTooltipOpen(false);
  };

  const handleStakeMultiplierTooltipOpen = () => {
    setStakeMultiplierTooltipOpen(true);
  };

  if (!syndicates) return null;

  if (!syndicate) {
    return <Redirect to={"/clear"} />
  }

  if (!round) return null;

  let theme = window.theme;  

  const openToDate = new Date(round.openTo);
  let locale = t('locale'); 

  const nf = PoolXNumberFormat(locale, { style: 'currency', currency: syndicate.stakePerShare.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let formattedStakePerShare = nf.format(syndicate.totalAmountPerShare.amount);
  let feePercentage = Math.round((syndicate.agentFeePerShare.amount / syndicate.stakePerShare.amount * 100)) + "%";

  if (!(config.syndicateBetting || config.agentSyndicateBetting)) {
    return <div />;
  }

  let isRealMode = player && player._embedded && player._embedded.mode === 'real';

  let rowStyle = {
    background: 'var(--background_1)',
    alignItems: 'center',
    paddingTop: '30px',
    paddingBottom: '10px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: "auto",
    padding: "5px"
  }

  let isReduced = syndicate.pendingRoundCoupon.bets[0].system.type === "REDUCED";
  let isSingle = syndicate.pendingRoundCoupon.bets.length > 1;
  let reducedSystemName = "";
  if (isReduced) {
    reducedSystemName = getReducedFiendlyName(syndicate.pendingRoundCoupon.bets[0].system.reducedSystemId)
  }

  return (
    <>
      <NavBar whichToUnderline="2" />
      <SyndicateWrapper>       
        <div className="green-div-column">
          <H2 className="title-margin">{syndicate.name}</H2>
          <span className="title-under">{getRoundName(round, t)} <Moment format={"L"} locale={locale}>{openToDate}</Moment></span>
        </div>
        <Grid container direction="row" justifyContent="center">
          <Grid container sx={rowStyle}>
            <Typography>{t('share_price')}:</Typography>
            <Typography className="bold">{formattedStakePerShare} </Typography>
          </Grid>
          <Grid container sx={rowStyle}>
            <Typography>{t('shares')}:</Typography>
            <Typography className="bold">{syndicate.targetNShares} </Typography>
          </Grid>
          <Grid container sx={rowStyle}>
            <Typography>{t('sold_shares')}:</Typography>
            <Typography className="bold">{syndicate.nShares} </Typography>
          </Grid>
          <Grid container sx={rowStyle}>
            <Typography>{t('fee')}:</Typography>
            <Typography className="bold">{feePercentage}</Typography>
          </Grid>
        </Grid>
        {isReduced &&
          <Grid container direction="row" justifyContent="center">
            <Grid container sx={rowStyle}>              
              <ClickAwayListener onClickAway={handleStakeMultiplierTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleStakeMultiplierTooltipClose}
                    open={stakeMultiplierTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top-end"
                    title={t('reduced_disclaimer')}
                  >
                    <IconButton onClick={handleStakeMultiplierTooltipOpen}>
                      <InfoOutlinedIcon sx={{ fontSize: "15px", color: 'var(--off-black)' }} height="20" width="20" />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
              <Typography>{t('reduced_system')}:</Typography>
              <Typography className="bold">{reducedSystemName} </Typography>
            </Grid>
          </Grid>
        }
        {isSingle &&
          <Grid container direction="row" justifyContent="center">
            <Grid container sx={rowStyle}>
              <Typography>{t('single_rows')}</Typography>
            </Grid>
          </Grid>
        }
        <div className="white-div">
          {showConfirm && (
            <SyndicateConfirm
              toggle={setShowConfirm}
              syndicate={syndicate}
            />
          )}
          {syndicate.nShares < syndicate.targetNShares && (<Button disabled={!isRealMode} onClick={() => setShowConfirm(true)}>{t('pay') + ' ' + formattedStakePerShare}</Button>)}
          <SyndicateCoupon syndicate={syndicate} round={round} theme={theme}></SyndicateCoupon>
        </div>        
      </SyndicateWrapper>
    </>
  );
}