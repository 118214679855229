export const TRANSLATIONS_TR = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    my_bets: "Bahislerim",
    my_coupons: "Kuponlarım",
    rounds: "Turlar",
    bet_with_syndicates: "Sendikalar",
    syndicates: "Sendikalar",
    turnover: "Mevcut Havuz",
    single_rows: "Tek satırlar",
    pro_pick: "Pro pick",
    often: "Sık sık kazanın",
    a_lot: "Çok kazanın",
    system: "Sistem",
    row_distribution: "Satır dağıtımı",
    overview: "Genel Bakış",
    full_cover: "Tam kapsamlı",
    half_cover: "Yarım kapsamlı",
    row_price: "Satır fiyatı",
    pay: "bahis yap",
    unsettled_coupons: "Ödemesi yapılmamış kuponlar",
    settled_coupons: "Alınmış kuponlar",
    stake: "Bahis",
    coupon_share_status_cancelled: "İptal edildi",
    your_winnings_right_now: "Şu andaki kazancınız",
    correct_rows_now: "Şuanki doğru seçimler",
    starts: "Başlangıç",
    correct_rows: "Satırları düzelt",
    prize: "Ödül",
    your_rows: "Satırlarınız",
    profit: "Kâr",
    st:"",
    total: "Sonuç",
    rows: "satırlar",
    row: "sıra",
    price_per_row: "Satır başına miktar",
    total_cost: "Toplam tutar",
    paid: "Paralı",
    settled: "Yerleşik",
    channel_internet: "Kanal: İnternet",
    print: "Yazdır",
    share: "Paylaş",
    clear_bets: "Bahisleri Temizle",
    round_history: "Tur geçmişi",
    no_profit: "Kar yok",
    no_coupons: "Kupon Yok",
    started: "Başladı",
    round: "Yuvarlak",
    winners: "Kazananlar",
    your_bet_is_paid: "Bahsiniz alındı!",
    do_you_want_to_place_bet: "Bahis oynamak ister misiniz?",
    highest_win: "En yüksek kazanç",
    insufficient_funds: "Yetersiz bakiye",
    cancel: "İptal et",
    unable_to_complete_purchase: "Alma şlemi tamamlanamıyor",
    error_code: "Hata kodu",
    event_goal: "Amaç",
    event_penalty: "Ceza",
    event_missed_penalty: "Kaçırılan Penaltı",
    event_own_goal: "Kendi kalesine gol",
    event_var: "VAR",
    event_yellowcard: "Sarı kart",
    event_yellowred: "İkinci Sarı Kart",
    event_substitution: "Oyuncu değişikliği",
    event_redcard: "Kırmızı kart",
    event_pen_shootout_goal: "Penaltıdan Gol",
    event_pen_shootout_miss: "Kaçırılan Penaltı",
    event_full_time: "Tam zaman",
    event_half_time: "İlk Yarı",
    event_kickoff: "Başlangıç",
    event_finished_after_extra: "Uzatma Süresi Sona Erdi",
    out: "Dışarı",
    table: "Masa",
    game_info: "Oyun bilgisi",
    statistics: "İstatistik",
    team_name: "Takım",
    table_abbreviation_num_games: "Oy.",
    table_abbreviation_wins: "G",
    table_abbreviation_draws: "B",
    table_abbreviation_losses: "M",
    table_abbreviation_points: "Pu.",
    round_active_after_deadline: "Kapalı",
    full_time: "Tam zaman",
    round_status_finished: "Bitmiş",
    half_time: "İlk Yarı",
    current_rounds: "Güncel Turlar",
    settled_rounds: "Alınmış Turlar",
    no_rounds_available: "Tur yok",
    row_statistics: "Satır istatistikleri",
    showing: "Gösterilen",
    settings: "Ayarlar",
    match: "Maç",
    result: "Sonuç",
    bet_type: "Bahis türü",
    mathematical_system: "Matematiksel sistem",
    select: "Seçme",
    is_draw: "İle çekilecek",
    is_drawn: "çizilmiş",
    draw_disclaimer: "Kuraya tabi olunması halinde:",
    num_correct_win: "Numara doğru/kazandı",
    more_info: "Daha fazla bilgi",
    in_running: "Koşarken",
    go_back: "Geri gitmek",
    to_syndicate: "Sendikasyon yapmak",
    shares: "Paylaşımlar",
    share_price: "Paylaşılan tutar",
    sold_shares: "Satılan paylaşımlar",
    agent_admin: "Temsilci yöneticisi",
    agents: "Temsilciler",
    to_agent: "Temsilciye",
    create_new_syndicate: "Yeni sendika oluştur",
    name: "İsim",
    target_number_of_shares: "Hedef hisse sayısı",
    max_number_of_shares: "Maksimum hisse sayısı",
    submit_syndicate: "Sendikayı gönder",
    update_syndicate: "Sendikayı güncelle",
    show_sv_percent: "Oyuncuların yüzdesini göster",
    show_coupon_percent: "Kupon yüzdesini göster",
    number_of_rows: "Satır sayısı",
    share_of_syndicate: "Sendika payı",
    syndicate_created: "Sendika oluşturuldu",
    syndicate_submitted: "Sendika gönderildi",
    syndicate_updated: "Sendika güncellendi",
    finished: "Bitmiş",
    started_games: "Başlatıldı",
    potential: "Potansiyel",
    sort_by_finished: "Bitmişe göre sırala",
    game_summary: "Oyun özeti",
    date: "Tarih",
    league: "Lig",
    venue: "mekan",
    weather: "Hava durumu",
    temperature: "Sıcaklık",
    wind: "Rüzgâr",
    moisture: "Nem",
    general_info: "Genel Bilgi",
    locale: 'tr-TR',
    show: "Göstermek",
    show_round: "Turu göster",
    play_on: "Oynamak",
    today: "Bugün",
    latest_games: "En yeni oyunlar",
    head_to_head: "Kafa kafaya",
    round_information_short: "Bilgi",
    round_information: "Yuvarlak bilgi",
    prize_groups: "Ödül grupları",
    bet: "Bahis",
    pro_pick_bets: "Pro pick bahisleri",
    help: "Yardım",
    round_status_open: "Açık",
    round_status_voided: "İptal edildi",
    round_status_paused: "Duraklatıldı",
    round_paused_explanation: "Bahis yapmak için tur geçici olarak duraklatıldı",
    jackpot: "Tahmini İkramiye",
    after_extra_time: "Uzatma Süresinin Ardından",
    show_rows: "Satırlarımı göster",
    id: "İD",
    bet_title: "Bahis",
    follow_title: "Takip et",
    win_title: "Kazanç",
    bet_text: "Turdaki futbol oyunlarının her birinde bir veya daha fazla sonuç seçin",
    follow_text: "Kazançlarınızı siteden canlı olarak takip edin",
    win_text: "Yeterince doğru sonucu seçtiyseniz havuzun kazananlarından biri olacaksınız.",
    share_of_prizepool: "Ödül havuzunun payı",
    register: "Kayıt ol",
    fee_per_row: "Satır başına ücret",
    bet_status_open: "Açık",
    bet_status_won: "Kazanmak",
    bet_status_lost: "Kayıp",
    payout: "Ödeme",
    show_details: "Detayları göster",
    bet_now: "Şimdi bahis yapın",
    place_more: "Kazanma şansınızı artırmak istiyorsanız her oyunda birden fazla sonuç seçebilirsiniz.",
    active_syndicates: "aktif sendikalar",
    settled_syndicates: "yerleşik sendikalar",
    win_multiplier: "Kazanma çarpanı",
    win_multiplier_information: "Bahis tutarınız ve potansiyel kazancınız seçilen kazanç çarpanı ile çarpılır",
    status: "Durum",
    join_title: "Gruba katıl",
    join_text: "Diğer oyuncularla birlikte daha büyük bir sistemde yer alın ve büyük ikramiyeyi kazanma şansınızı artırın.",
    valid_title: "Her zaman geçerli",
    valid_text: "Bahsiniz, kontenjan tükenmese bile her zaman geçerlidir.",
    win_together_title: "Birlikte kazanın",
    win_together_text: "Sendikadan elde edilen kazançlar hisseler arasında paylaştırılır.",
    balance: "Balance",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
}
